<template>
  <div class="pb-3">
    <v-row justify="center" align="center">
      <template
        v-for="(sponsor, j) in items"
      >
        <v-col
          v-if="sponsor.SponsorType.key == 'SPR300'" 
          :key="j"
          cols="12"
        >
          <Sponsor :item="sponsor"/>
        </v-col>
      </template>
    </v-row>
    <v-row justify="center" align="center">
      <template
        v-for="(sponsor, j) in items"
      >
        <v-col
          v-if="sponsor.SponsorType.key == 'SPR100'" 
          :key="j"
          cols="6"
        >
          <Sponsor :item="sponsor"/>
        </v-col>
      </template>
    </v-row>
    <v-row justify="center" align="center">
      <template
        v-for="(sponsor, j) in items"
      >
        <v-col
          v-if="sponsor.SponsorType.key == 'SPR30'" 
          :key="j"
          cols="4"
        >
          <Sponsor :item="sponsor"/>
        </v-col>
      </template>
    </v-row>
    <v-row justify="center" align="center">
      <template
        v-for="(sponsor, j) in items"
      >
        <v-col
          v-if="sponsor.SponsorType.key == 'SPR10'" 
          :key="j"
          cols="3"
        >
          <Sponsor :item="sponsor"/>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    Sponsor: () =>
      import(
        /* webpackChunkName: "component-sponsor" */ "@/components/Sponsor/Sponsor.vue"
      ),
  },
  computed: mapState({
    //
  }),
  props:[
    'items',
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>